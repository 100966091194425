/*
일단 media 태그가 없는 상태로 refactoring한다.
필요하다고 생각이 들면, css/user/contents.css 에서 다시 가져온다.
 */

/*********************** #detail_con ***********************/
/*테이블가이드*/
table {
  width:100%;
  margin:0 auto;
  border-top:2px solid #777777;
  border-bottom:1px solid #d0cbbb;
  line-height:22px;
  
	@include breakpoint(small) {
		word-break: break-all;
	}

  th {
    background-color:#f9f9f9;
    border-top:1px solid #d0cbbb;
    border-left:0px solid #d0cbbb;
    border-right:1px solid #d0cbbb;
    padding: 0.5rem 0.3rem 0.5rem 0.3rem;
    font-weight:bold;
    font-size:14px;
  }
  td {
    border-top:1px solid #d0cbbb;
    border-left:1px solid #d0cbbb;
    border-right:0px;
    text-align:center;
    vertical-align:middle;
    padding: 0.5rem 0.3rem 0.5rem 0.3rem;
    height:40px;
    font-size:14px;
  }
  .ans td {
    text-align:left;
    vertical-align:middle;
    height:auto;
    font-size:14px;
    padding:0;
    p {
        text-align: left;
        margin-top: 10px;
        margin-bottom: 10px;
        p {
          margin-left: 10px;
          border-top: 1px solid #9c9c9c;
          padding-top: 10px;
        }
      }
  }
}

.sblet { padding-left:20px; font-size:16px; font-weight:600; color:#28587f; background:url(/images/sub/sblet.gif) no-repeat left 4px; }
.ssblet { margin-bottom:8px; padding-left:15px; background:url(/images/sub/ssblet.gif) no-repeat left 6px; }
.sssblet { margin-top:3px; padding-left:9px; background:url(/images/sub/sssblet.gif) no-repeat left 8px; }
.ssssblet { padding-left:9px; font-size:12px; background:url(/images/sub/ssssblet.gif) no-repeat left 10px; }

.blue_font { color:#2173bf; }
.tbox { padding:20px 30px; border:1px solid #afafaf; border-top:3px solid #424242; }
.tbox2 { padding:25px 30px 40px; border:1px solid #ccc; background:url(/images/sub/email_bbg.gif) repeat-x left bottom;}

.blueBtn { display:inline-block; }
/*display:inline; padding:5px 15px 6px; font-weight:600; color:#fff !important; background:#2173bf; }*/

a.btn { display:inline-block; margin-bottom:5px; font-size:14px; font-weight:600; color:#fff !important; background:#2173bf; }
a.btn span { display:block; padding:5px 15px 6px 48px; }
a.btn .window { background:url(/images/sub/btn_window.gif) no-repeat; }
a.btn .down { background:url(/images/sub/btn_down.gif) no-repeat; }


/****************************** table.data ******************************/
table.data { border-top:2px solid #2173bf; }
table.data thead th { text-align:center; padding:10px; border-left:1px solid #1f6fba; background:#f3f6f8; }
/*table.data thead tr:first-child th { border-top:2px solid #2173bf; }*/
table.data thead tr:first-child th:first-child { border-left:none; }
table.data thead tr.sec th { border-top:1px solid #1f6fba; }
table.data td { text-align:center; padding:10px; border-top:1px solid #c1c4c5; border-bottom:1px solid #c1c4c5; border-left:1px solid #c1c4c5; }
/*table.data th, table.data td { word-break:break-all; white-space:pre-wrap; }*/
table.data tbody th { text-align:center; padding:10px; border-top:1px solid #c1c4c5; border-bottom:1px solid #c1c4c5; background:#fafafa; }
table.data tbody th.bdL { border-left:1px solid #c1c4c5; }
table.data td.left { text-align:left; padding-left:15px; }
table.data td.right { text-align:right; }
table.data td:first-child { border-left:none; }
table.data td.line { border-left:1px solid #c1c4c5; }
table.data .lineR { border-right:1px solid #c3c3c3; }
table.data.many td { font-size:12px; }

/****************************** classes ******************************/
.ci { overflow:hidden; }
.ci>div { float:right; width:48%; }
.ci>div img { max-width:95%; }
.ci>div:first-child { float:left; }
.ci>div>div { text-align:center; padding:60px 0; margin-top:10px; border:1px solid #c8c8c8; background:#f9f9f9; }
.character { border:1px solid #c8c8c8; overflow:hidden; }
.character .img { float:left; width:35%; padding:10px 0; text-align:center; border-right:1px dotted #c8c8c8; background:url(/images/sub/character_bg.gif) repeat; }
.character .con { float:right; width:55%; padding-top:40px; margin-right:40px; }
.character p.tit { line-height:25px; font-size:16px; font-weight:600; padding-bottom:15px; }
.character p.tit span { display:block; font-size:20px; color:#3f86c8; }
.social_plan { margin-top:2%; padding:4% 0%; border:1px solid #ccc; overflow:hidden; }
.social_plan>div { float:left; width:33%; }
.social_plan>div.plan2 { border-left:1px dotted #ccc; border-right:1px dotted #ccc; }
.social_plan>div p.tit { padding:5% 10%; text-align:center; font-size:20px; font-weight:bold; }
.social_plan>div ul.list { padding:4% 12% 4%; font-size:14px; }
.social_plan>div ul.list li { margin:3% 0%; }
.social_organ { position:relative; margin-top:30px; }
.social_organ>div { position:relative; padding:20px; }
.social_organ>div.organ01 { background:#e7f4f8; text-align:center; border-radius:10px; z-index:1 }
.social_organ>div.organ03 { background:#f0f0f0; border-radius:10px; z-index:1 }
.social_organ>div p.position { position:absolute; left:30px; top:43%; color:#484848; font-size:16px; font-weight:bold; }
.social_organ>div.organ01 p.position { color:#096482 }
.social_organ>div.organ03 p.position.position_bu { top:22%; }
.social_organ>div.organ03 p.position_bong { top:62% }
.social_organ>div .part { overflow:hidden; }
.social_organ>div .part li { float:left; text-align:center; }
.social_history { overflow:hidden; }
.social_history>div p.step { font-weight:bold; font-size:18px; }
.social_history>div.history01 p.step { color:#244086; }
.social_history>div.history02 p.step { color:#4e395b; }
.social_history>div.history03 p.step { color:#20554e; }
.social_history>div.history04 p.step { color:#2b4e20; }
.social_history>div div.con p.year { height:30px; line-height:30px; text-align:center; font-weight:bold; font-size:15px; }
.social_history>div.history01 div.con p.year { background:#e4ecff; border:1px solid #8fa2d1; }
.social_history>div.history02 div.con p.year { background:#f5e7fe; border:1px solid #b18cc7; }
.social_history>div.history03 div.con p.year { background:#d5f0ec; border:1px solid #7da09b; }
.social_history>div.history04 div.con p.year { background:#e4f6df; border:1px solid #88bd77; }
.social_history>div div.con ul.list { border:1px solid #ccc; border-top:0px }
.social_activity01 { overflow:hidden; } 
.social_activity01 li { float:left; margin:2% 1% 1%; width:47%; padding-bottom:1%; } 
.social_activity02 { margin-top:1%; overflow:hidden; }
.social_activity02 li { float:left; margin:.5% 1.5%; padding-bottom:1%; }
.social_activity02 li p.txt { padding:3% 2%; text-align:justify; }
.social_activity02.other_activity li { min-height:170px; }
.social_activity02.other_activity li p.txt { text-align:center; font-weight:bold; }
.activity { margin-top:30px; line-height:25px; text-align:center; font-size:16px; }
.activity .tit { margin-bottom:20px; font-size:24px; line-height:30px; color:#0089d1; font-weight:600; }
.activity img { margin-top:40px; margin-bottom:50px; }
.pledge { padding:30px; font-weight:600; }
.pledge p { clear:left; margin-bottom:20px; font-size:16px; line-height:24px; overflow:hidden; }
.pledge .tit { text-align:center; margin-bottom:30px; padding-top:120px; font-size:22px; line-height:30px; color:#0c6db0; }
.pledge.voluntary .tit { background:url(/images/sub/voluntary_icon.gif) no-repeat center top; }
.pledge.vision .tit { background:url(/images/sub/vision_icon.gif) no-repeat center top; }
.pledge.service .tit { background:url(/images/sub/service_icon.gif) no-repeat center top; }
.pledge .end { text-align:center; margin-top:100px; font-size:18px; }
.ceo_successive { padding-bottom:5px; overflow:hidden; font-size:15px; font-weight:600; }
.ceo_successive li { float:left; text-align:center; width:23%; margin-left:2%; margin-bottom:30px; padding:22px 0 15px; box-shadow:1px 2px 3px #ececec; border:1px solid #e0e0e0; background:url(/images/sub/successive_bbg.gif) repeat-x left bottom; }
.ceo_successive li:first-child { margin-left:0; }
.ceo_successive li .photo { padding:8px 0; background:url(/images/sub/successive_photo.gif) no-repeat center center; }
.ceo_successive li .name { width:80%; margin:0 auto 5px; padding:5px 0; font-size:17px; color:#004ad0; border-bottom:1px solid #cdcdcd; }
.greeting p { margin:20px 10px; }
.greeting .intro { font-size:16px; font-weight:600; }
.ceo_profile { background:url(/images/sub/ceo_profile_photo.jpg) no-repeat }
.ceo_profile div.con { padding:3% 1%; overflow:hidden; border-top:1px dotted #dbdbdb; }
.ceo_profile div.con:first-child { border-top:0px; }
.ceo_profile div.con p.tit { margin-top:5px; width:95px; font-size:18px; font-weight:bold; }
.ceo_profile div.con ul li { overflow:hidden; padding:1% 0px; font-size:14px; }
.ceo_profile div.con ul li span { display:block; float:left }
.ceo_profile div.con ul li span.date { width:145px; font-weight:bold; color:#2173bf }
.found>div { position:relative; top:-20px}
.found>div p { padding-top:17px; font-size:14px; line-height:25px; }
.found>div p:first-child { padding-top:0px; }
.outlink { text-align:center; /* margin-top:70px; background:url(/images/sub/outlink_bg.gif) no-repeat center top;*/ }
.outlink .tit { padding-top:39px; font-size:17px; color:#fff; font-weight:600; }
.outlink>img { margin-bottom:20px; }
.outlink>a span { padding-right:40px; padding-left:70px; }
.history { padding-right:380px; font-size:14px; }
.history.y2010 { background:url(/images/sub/history2010_bg.jpg) no-repeat right 50px; }
.history.y2000 { background:url(/images/sub/history2000_bg.jpg) no-repeat right 50px; }
.history.y1990 { background:url(/images/sub/history1990_bg.jpg) no-repeat right 50px; }
.history.y1980 { background:url(/images/sub/history1980_bg.jpg) no-repeat right 50px; }
.history>div { overflow:hidden; padding:25px 15px; background:url(/images/sub/dotline.gif) repeat-x left bottom; }
.history>div:first-child { padding-top:10px; }
.history>div .year { float:left; width:17%; margin-bottom:10px;  color:#366388; font-size:20px; font-weight:600; }
.history>div dl { float:left; width:82%; }
.history>div dt { float:left; width:18%; padding-left:9px; margin-bottom:5px; font-weight:600; background:url(/images/sub/point_blet.gif) no-repeat left 8px; }
.history>div dd { float:left; width:78%; margin-bottom:5px; }
.public_intro { overflow:hidden; }
.public_intro>li { float:left; width:33%; margin-bottom:30px; }
.email { padding:30px 50px; border:1px solid #ccc; background:url(/images/sub/email_bbg.gif) repeat-x left bottom; }
.email>div { padding:50px 0 20px 220px; background:url(/images/sub/email_bg.gif) no-repeat left center; }
.email>div p { margin-bottom:25px; line-height:23px; }
.clean { text-align:center; }
.clean p.txt { font-size:16px; line-height:25px; }
.clean p { margin-top:30px; }
.govern30_link { padding:10px 0px 20px 30px; overflow:hidden; }
.govern30_link li { float:left; margin-bottom:10px; width:135px; }

/*사이트맵 */
table.sitemap {
  border-top:2px solid #777777;
  border-bottom:1px solid #d0cbbb;
  th {
    border:1px solid #d0cbbb;
    background-color: #f9f9f9;
    color: #222222;
    font-size: 85%;
    text-align: center;

    &.text_01 {
      font-size: 100%;
      color:#d7177d;
      padding: 1rem 0;
    }
  }
  td {
    vertical-align: top;
    border:1px solid #dddddd;
    text-align:left;
    font-size: 80%;
    color:#777777;
    ol {
      margin: 10px 0;
      list-style: none;
      li {
        line-height: 1.3;
        margin-top: 8px;
      }
    }
  }
  th:first-child, td:first-child { border-left: 0; }
  th:last-child , td:last-child { border-right:0px;}
}

.sub_visual {position:relative;}
.sub_visual .homepage {position:absolute;top:-5px;left:20px;}
.sub_visual .homepage2 {position:absolute;top:-5px;left:20px;}
.sub_visual .txt1 {position:absolute;top:280px;left:20px;background:url('/images/sub/submain_txt_bg.png');padding:10px;display:inline-block;text-align:right;font-size:24px;color:#fff;}
.sub_visual .txt2 {position:absolute;top:330px;left:20px;background:url('/images/sub/submain_txt_bg.png');padding:10px;display:inline-block;text-align:right;font-size:20px;color:#fff;}

.ready {width:100%;text-align:center;font-size:20px;color:#000;font-weight:bold;;padding:100px 0 100px 0;}
.description_top {font-size: 13px; background:#fff;border-top:3px solid #258fd0;}

#tx_specialchar_menu .tx-menu-inner {margin-left:-500px;}
#tx_emoticon_menu .unselectable table {border-top:none;}

.faq_q {background:url(/images/icon_q.png) no-repeat 5px 7px;text-align:left !important; font-weight:bold;border-left:1px solid #fff !important;padding-left:40px !important;}
.faq_a {background:#f3f3f3 url(/images/icon_a.png) no-repeat 5px 7px;text-align:left;padding-left:40px !important;border-left:1px solid #fff !important;font-weight:normal;}




.txt_red {font-weight:bold;color:#ff0000; font-size:17px;}
.txt_green {font-weight:bold;color:#6d9a00;}
.txt_blue {font-weight:bold;color:#3c34ac;}

.report { overflow:hidden; }
.report>div { float:left; position:relative; text-align:center; margin-left:4%; padding:30px 0; margin-bottom:30px; }
.report>div:first-child { margin-left:0; }
.report>div p:first-child {  position:static; margin:0; text-align:center; }
.report>div p { position:absolute; top:125px; margin:27px; text-align:left; }
.report .con1 { border:1px solid #8fa2d1; background:#e4ecff; }
.report .con2 { border:1px solid #b18cc7; background:#f5e7fe; }
.report .con3 { border:1px solid #7da09b; background:#d5f0ec; }
.report .con4 { border:1px solid #88bd77; background:#e4f6df; }
.report .con5 { border:1px solid #dc95b7; background:#f7deea; }
.report .con6 { border:1px solid #c7af99; background:#fff1e4; }
.report>div>div { overflow:hidden; position:absolute; bottom:25px; width:99%; }
.floor { border-top:2px solid #424242; }
.floor dl { overflow:hidden; background:url(/images/sub/floor_line.png) repeat-x left bottom; }
.floor .bg { background-color:#f7f7f7; }
.floor dt { width:80px; margin-right:25px; font-weight:600; text-align:center; letter-spacing:-1px; }
.floor dt, .floor dd { float:left; font-size:14px; padding:10px 0px; }
.floor dd { margin-right:20px; font-size:13px; }
.floor .ground { position:relative; }
.floor .ground dt { color:#244086; border:1px solid #8fa2d1; border-top:none; background:#d1deff; }
.floor .ground .bg dt { border:1px solid #8fa2d1; border-top:none; background:#e4ecff; }
.floor .ground div.img { position:absolute; right:0px; top:164px;  }
.floor .base dt { color:#943161; border:1px solid #dc95b7; border-top:none; background:#f1c7db; }
.floor .base .bg dt { border:1px solid #dc95b7; border-top:none; background:#f7deea; }


/*********************** #detail_con ***********************/
main {
  /*약관*/
  .generalboard .heading_03 { margin-top:0px; padding-left:0px; font-weight:bold; font-size:22px; color:#b04baa; } 
  .generalboard .golf_box .inner {text-indent:100px;}
  .generalboard .blank_01 th {width:12%;}

  .generalboard .blank_01 .align_01 {text-align:left; padding-left:20px; padding-top:20px; padding-bottom:20px;}
  .generalboard .blank_02 .align_01 {text-align:left; padding-left:20px; padding-top:20px; padding-bottom:20px;}
  .generalboard .blank_01 .align_01 .pop {font-size:12px;padding-top:10px;}
  .generalboard .blank_08 .blank_01 .txt_s{color:202abb; font-size:16px;}


  .generalboard .em_bg {width:100%; background:url('/images/sub/em_bg.png') repeat; padding-left:-20px; padding: 0 3% 3% 0;}
  .generalboard .em_bg .txt_100 { padding-left: 27%; font-size:18px; font-weight:bold; margin-top:30px;}
  .generalboard .em_bg .txt_101 { padding-left: 27%; font-size:12px;}
  .generalboard .txt_05 { padding-top:30px; padding-bottom:0px; font-size:15px; color:#0191ea; font-weight:bold;}
  .generalboard .concen { padding-top:0px; width:100%; text-align:center;}


  img.img { border-bottom:2px solid #e3e3e3; }
  .tx-menu-list-item img { max-width: none !important }
  img { max-width:100%; }

  /*blet*/
 /* h4 { margin-top:40px; padding-left:0px; font-size:18px; color:#222222;} blet h로 교체*/
  /*contents*/
  .sitemap { overflow:hidden; margin-top:30px; }
  .sitemap_btn a { display:inline-block; width:114px; height:22px; font-size:12px; font-weight:600; }
  .sitemap_btn a span { padding-right:10px; }
  .sitemap_btn a.open { background:url(/images/sub/sitemap_allopen.gif) no-repeat; }
  .sitemap_btn a.close { background:url(/images/sub/sitemap_allclose.gif) no-repeat; }
  .sitemap>div { float:left; width:31%; margin-left:20px; }
  .sitemap>div:first-child { margin-left:0; }
  .sitemap .title { text-align:center; padding:10px 0; border:1px solid #8fa2d1; font-size:18px; color:#244086; font-weight:700; background:url(/images/sub/sitemap_title.gif) repeat; }
  .sitemap .facility { display:block; text-align:center; padding:7px 0; margin-top:10px; border-bottom:2px solid #595959; font-size:16px; font-weight:600; }
  .sitemap .subdepth>li {  border-bottom:1px solid #b4b4b4; }
  .sitemap .subdepth>li>a { display:block; padding:7px 10px; font-size:14px; font-weight:600; background:url(/images/sub/sitemap_open.gif) no-repeat 98% center; }
  .sitemap .subdepth>li>a.on { background:url(/images/sub/sitemap_close.gif) no-repeat 95% center; }
  .sitemap .bg_n { background:none !important; }
  .sitemap .subdepth2 { /*display:none;*/ padding:10px 18px; border-top:1px solid #b4b4b4; background:#f3f3f3; }
  .sitemap .subdepth2>li { margin-bottom:3px; padding-left:8px; background:url(/images/sub/sitemap_blet.gif) no-repeat left 7px; }
  .login { overflow:hidden; }
  .login div { position:relative; min-height:220px; width:49%; border:1px solid #ccc; }
  .login div p { padding:0.5rem; height:175px; 
  	@media #{$small-only} {
      	height: 215px;
      }
  }
  .login div .tit { padding-top:30px; padding-bottom:0; font-size:16px; font-weight:600; }
  .login div a { position:absolute; bottom:20px; left:50%; margin-left:-75px; font-size:12px;}
  .login div.phone a { left:60%; }
  .login .phone { float:right;  border-top:3px solid #8fa2d1; }
  .login .phone .tit { color:#244086; height:60px;}
  .login .point { margin-top:20px; color:red; margin-bottom: 60px;}
  .login .phone a span { padding:6px 0 6px 30px; background:url(/images/sub/login_btn1.gif) no-repeat; }
  .login .ipin { float:left; border-top:3px solid #b18cc7; }
  .login .ipin .tit { color:#4e395b; height:60px;}
  .login .ipin a span { padding:6px 0 6px 30px; background:url(/images/sub/login_btn2.gif) no-repeat; }
  .banner { overflow:hidden; }
  .banner li { float:left; width:30%; margin-left:2%; margin-bottom:20px; text-align:center; padding:20px 0; border:1px solid #dbdbdb; border-top:2px solid #1f6fba; background:url(/images/sub/email_bbg.gif) repeat-x left bottom; }
  .banner li a { display:block; width:90%; margin:0 auto; }
  .banner li a span { display:block; padding-top:15px; margin-top:10px; border-top:1px solid #d9d9d9; }
  .homepage li { margin-top:20px; }
  .homepage2 li { margin-top:20px; }
  #map-canvas { border:1px solid #bbb; }
  /*서브가이드*/
  .heading_01 { margin-top:50px; padding-left:0px; font-weight:bold; font-size:22px; color:#b04baa; } 
  .heading_02 { margin-top:20px; padding-left:0px; font-size:14px; color:#555555; line-height:24px; }
  .heading_02 .pad_01 {padding-top:5px; padding-left:10px;} 
  .txt_01 { margin-top:20px; padding-left:0px; font-weight:bold; font-size:14px; color:#555555; line-height:24px; } 
  .heading_04 { margin-top:20px; padding-left:0px; font-weight:bold; font-size:16px; color:#0100FF; line-height:16px; }

  /*골프장 시설안내*/
  .golf_align { text-align:center;padding-top:40px;}
  .golf_align p { font-weight:bold; font-size:16px;color:#555555; padding-top:15px; }
  .tb_01 {height:90px;}
  .price table { width:100%; margin:0 auto; border-top:2px solid #777777; border-bottom:1px solid #d0cbbb; display:block; }
  .price img { width:100%; display:none; }
  .price th { width:10%; height:50px; background-color:#f9f9f9; border-top:1px solid #d0cbbb; border-right:1px solid #d0cbbb; font-weight:bold; font-size:14px;}
  .price th:last-child { border-right:0px;}
  .price td { border-top:1px solid #d0cbbb; height:50px; border-left:0px; border-right:1px solid #d0cbbb; text-align:center; vertical-align:middle; font-size:14px; }
  .price td:last-child { border-right:0px; padding:5px; }
  .price table .align_07 { text-align:left; padding:20px 10px 20px 20px; }

  /*골프장 이용약관*/
  .golf_box { border-top:2px solid #777777; border:1px solid #cccccc; padding:0px 30px 30px 30px; }
  .golf_box .tit_01 { font-weight:bold; font-size:16px; color:#222222; padding-top:30px; padding-bottom:20px;}
  .golf_box .p { font-size:14px; color:#555555; padding-left:20px;}

  /*골프장 위치안내*/
  .golf_map { width:985px; height:533px; }
  .golf_p { text-align:center; font-weight:bold; font-size:16px; color:#555555; padding-top:15px; }

  /*국민체육센터 시설소개*/
  .scenter_img01 { width:100%; height:400px; background-image:url('/images/sub/scenter_img01.jpg'); vertical-align:top;  }
  .scenter_img01 .txt_01 {font-size:18px; font-weight:bold; color:#062d51; padding-left:40px; padding-top:40px; }
  .scenter_img01 .txt_02 {font-size:40px; color:#b04baa; padding-top:20px; padding-left:40px; }

  .scenter_img02 { width:100%; height:400px; background-image:url('/images/sub/pground_01.jpg'); vertical-align:top;  }
  .scenter_img02 .txt_01 {text-align:right; font-size:18px; font-weight:bold; color:#062d51; padding-right:30px; padding-top:40px; }
  .scenter_img02 .txt_02 {text-align:right; font-size:40px; color:#b04baa; padding-top:20px; padding-right:30px; }

  .scenter_img03 { width:100%; height:auto; background-image:url('/images/sub/corp_bg.gif'); vertical-align:top;  }
  .scenter_img03 .txt_01 {text-align:left; font-size:18px; font-weight:bold; color:#222222; padding-left:5px;}
  .scenter_img03 .txt_02 {text-align:left; font-size:14px; color:#555555; padding-top:20px; padding-left:5px; padding-bottom:20px; line-height:26px; }

  .scenter_img04 { width:100%; height:850px; background-image:url('/images/sub/corp_bg1.gif'); vertical-align:top;  }
  .scenter_img04 .txt_01 {text-align:left; font-size:18px; font-weight:bold; color:#222222; padding-left:5px;}
  .scenter_img04 .txt_011 {text-align:left; font-size:40px; color:#ff4e00; padding-left:5px;}
  .scenter_img04 .txt_02 {text-align:left; font-size:14px; color:#555555; padding-left:5px; padding-bottom:20px; line-height:26px; }
  .scenter_img04 .line_02 {width:80%; height:1px; background-color:#dddddd; margin-top:20px;}
  .scenter_img04 .line_03 {width:80%; height:1px; background-color:#dddddd; margin-top:10px;}
  .scenter_img04 ul {overflow: auto; list-style-type: none;  vertical-align:middle;}
  .scenter_img04 li {float: left; display: inline; vertical-align:middle; padding-left:15px;}
  .scenter_img04 ul .txt_001 {font-weight:bold; color:#222222;}

  .corp_wrap {width:100%; margin:auto; text-align:center; position:relative; }
  .corp_wrap .scenter_img05 { width:100%; height:378px; background-size: contain; background-image:url('/images/sub/corp_bg3.gif'); background-position:center; background-repeat: no-repeat; margin:auto; text-align:center;  border:0px solid #444444;}
  .corp_wrap .scenter_img05 .corp_left {width:30%; height:75%; text-align:center; vertical-align:middle; padding-top:140px; float:left; border:0px solid #444444; margin-left:20%;}
  .corp_wrap .scenter_img05 .corp_right {width:30%;  height:75%; text-align:center; vertical-align:middle; padding-top:140px; float:left; border:0px solid #444444; margin-right:20%;}
  .corp_wrap .scenter_img05 .corp_left h2 { width:100%; font-size:38px; font-weight:300; color:#175996; margin:auto; }
  .corp_wrap .scenter_img05 .corp_left .text_011 {width:100%; font-size:16px; color:#777777; margin:auto; padding-top:30px; line-height:24px;}
  .corp_wrap .scenter_img05 .corp_right h2 {width:100%; font-size:38px; font-weight:300; color:#175996; margin:auto; }
  .corp_wrap .scenter_img05 .corp_right .text_022 {width:100%; font-size:16px; color:#777777; margin:auto; padding-top:30px; line-height:24px;}
  .corp_wrap .goal {width:100%; clear:both;}
  .corp_wrap h3 { clear:both; font-size:22px; font-weight:bold; color:#1387ce; text-align:center; vertical-align:top; padding-bottom:40px;}
  .corp_wrap .goal_01 { width:24%; height:60px; float:left; font-size:18px; text-align:center; background-size: contain; background-image:url('/images/sub/corp_ico01.gif'); background-repeat: no-repeat; padding-top:130px; border-top: 1px solid #555555; border-bottom: 1px solid #cccccc; border-right: 1px solid #dddddd;}
  .corp_wrap .goal_02 { width:24%; height:60px; float:left; font-size:18px; text-align:center; background-size: contain; background-image:url('/images/sub/corp_ico02.gif'); background-repeat: no-repeat; padding-top:130px; border-top: 1px solid #555555; border-bottom: 1px solid #cccccc; border-right: 1px solid #dddddd;}
  .corp_wrap .goal_03 { width:24%; height:60px; float:left; font-size:18px; text-align:center; background-size: contain; background-image:url('/images/sub/corp_ico03.gif'); background-repeat: no-repeat; padding-top:130px; border-top: 1px solid #555555; border-bottom: 1px solid #cccccc; border-right: 1px solid #dddddd;}
  .corp_wrap .goal_04 { width:24%; height:60px; float:left; font-size:18px; text-align:center; background-size: contain; background-image:url('/images/sub/corp_ico04.gif'); background-repeat: no-repeat; padding-top:130px; border-top: 1px solid #555555; border-bottom: 1px solid #cccccc; border-right: 1px solid #dddddd;}
  .corp_wrap .goal_04:last-child {border-right:0px;}
  .corp_wrap h4 { font-size:22px; font-weight:bold; color:#1387ce; text-align:center; vertical-align:middle; padding-bottom:20px; padding-top:200px;}

  .corp_wrap .sub_tit07 {font-size:14px; color:#555555;}
  .corp_wrap .bgcorp { margin:0 auto; margin-top:20px; width:736; height:275; background-size: contain; background-image:url('/images/sub/bgcorp_01.gif'); background-position:center; background-repeat: no-repeat; }
  .corp_wrap .bgcorp .bgcorp_01 {width:45%; height:150px; margin-top:35px; vertical-align:middle; font-size:16px; font-weight:bold; color:#222222; float:left;}
  .corp_wrap .bgcorp .bgcorp_02 {width:10%; margin-top:35px; vertical-align:middle; font-size:16px; font-weight:bold; color:#222222; float:left;}

  .corp_wrap .corp_box {width:100%; margin:0 auto;}
  .corp_wrap .corp_box .corp_box01 {width:110px; float:left; margin:11px; text-align:left; padding:5px; border:1px solid #cccccc;}
  p.clear {clear: both;}
  .corp_wrap table { width:100%; margin:0 auto; border-top:2px solid #777777; border-bottom:1px solid #d0cbbb;}
  .corp_wrap th { width:20%; height:50px; background-color:#f9f9f9; border-top:1px solid #d0cbbb; border-right:1px solid #d0cbbb; font-weight:bold; font-size:14px;}
  .corp_wrap th:last-child { border-right:0px;}
  .corp_wrap td { border-top:1px solid #d0cbbb; height:50px; border-left:0px; border-right:1px solid #d0cbbb; text-align:center; vertical-align:middle; font-size:14px; }
  .corp_wrap td:last-child { border-right:0px; }
  .corp_wrap h5 { clear:both; font-size:22px; font-weight:bold; color:#1387ce; text-align:left; vertical-align:top; padding-bottom:20px; padding-top:40px;}
  .corp_wrap .alignF {width:5%; text-align:center; padding-left:0px;}
  .corp_wrap .align {width:50%; text-align:left; padding-left:20px;}
  .corp_wrap .alignM {width:20%; text-align:left; padding-left:20px;}
  .corp_wrap .alignD {width:25%; text-align:left; padding-left:20px;}
  .corp_wrap .align10 {width:10%; text-align:center; padding-left:5px;}
  .corp_wrap .align15 {width:15%; text-align:center; padding-left:5px;}
  .corp_wrap .align20 {width:20%; text-align:left; padding-left:5px;}
  .corp_wrap .align25 {width:25%; text-align:center; padding-left:20px;}
  .corp_wrap .align35 {width:25%; text-align:left; padding-left:20px;}
  .corp_wrap .align45 {width:45%; text-align:left; padding-left:20px;}
  .corp_wrap .align50 {width:50%; text-align:left; padding-left:20px;}

.corpService {
	padding: 0 3% 0 3%;
	font-size: 18px;
	font-weight: 600;
}
.corpService ul {
  	list-style: none !important;
	text-indent: -45px;
    padding: 6% 5% 0 5%;
}
.corpService li {
    margin-bottom: 30px;
}
.corpService p {
    padding: 3% 5% 3% 7%;
    text-align: center;
    word-break: keep-all;
}
.corpService .right {
	padding-top: 3%;
	padding-right: 5%;
    text-align: right;
}


  table.view {
    td.phone {
      p {
        text-align: left;
        vertical-align:middle;
        margin:0;
        padding-left: 30px;
        background: #fff url('/images/sub/ico_phone.png') no-repeat;
      }
    }
  }


  .center_0 {padding-top:50px; width:100%;}
  .center_0 .center_01 {width:50%;padding-top:30px; border-top:0px solid #dddddd; border-right:0px solid #dddddd; float:left;}
  .center_0 .center_01 .txt_01 {font-size:18px; color:#555555; font-weight:bold;}
  .center_0 .center_02 {width:50%; padding-top:30px; border-top:1px solid #dddddd; border-right:0px solid #dddddd; float:right;}
  .center_0 .center_02 .txt_01 {font-size:18px; color:#555555; font-weight:bold;}

  .center_0 .center_03 {width:50%;padding-top:30px; border-right:0px solid #dddddd; float:left;}

  /*국민체육센터 이용정보*/
  .time_info{background-color:#dae2fc; width:100%; height:180px; padding-top:50px; }
  .time_info .box_01 {width:20%; float:left; text-align:right; vertical-align:middle;}
  .time_info .box_02 { width:75%; float:right; vertical-align:middle;}
  .time_info .box_02 .btxt_01 {font-size:2.0em; line-height:36px; clear:both();}
  .time_info .box_02 .btxt_02 {padding-top:10px;}
  .time_info .box_02 .btxt_03 {width:65px; text-align:center; padding:3px 10px 3px 10px; background-color:#b04baa; font-size:16px; font-weight:bold; color:#ffffff;}
  .line_01 {height:1px; background-color:#dddddd; margin-top:20px;}

  /*다목적운동장*/
  .con {background-color:#eeeeee; height:132px;}
  .con .con_01 {width:60%;padding-top:10px; padding-left:30px; float:left;}
  .con .con_01 .txt_01 {font-size:18px; color:#555555; font-weight:bold;}
  .con .con_02 {width:30%; padding-top:40px; padding-right:20px; text-align:right; float:right;}

  .blank_01 {padding-top:20px; width:100%; clear:both; }
  .blank_01 table { width:100%; margin:0 auto; border-top:2px solid #777777; border-bottom:1px solid #d0cbbb;}
  .blank_01 th { width:20%; height:50px; background-color:#f9f9f9; border-top:1px solid #d0cbbb; border-right:1px solid #d0cbbb; font-weight:bold; font-size:14px;}
  .blank_01 th:last-child { border-right:0px;}
  .blank_01 td { border-top:1px solid #d0cbbb; border-left:0px; border-right:1px solid #d0cbbb; text-align:center; vertical-align:middle; font-size:14px; }
  .blank_01 td:last-child { border-right:0px; }


  .blank_02 {padding-top:20px; width:100%;}
  .blank_02 .concen {padding-top:20px; width:100%; text-algin:center;}
  .blank_02 table { width:100%; margin:0 auto; border-top:2px solid #777777; border-bottom:1px solid #d0cbbb;}
  .blank_02 th { width:10%; height:50px; background-color:#f9f9f9; border-top:1px solid #d0cbbb; border-right:1px solid #d0cbbb; font-weight:bold; font-size:14px;}
  .blank_02 th:last-child { border-right:0px;}
  .blank_02 td { width:10%; border-top:1px solid #d0cbbb; border-left:0px; border-right:1px solid #d0cbbb; font-size:14px; }
  .blank_02 td:last-child { border-right:0px; }
  .blank_02 .txt_03 { padding-top:30px; padding-bottom:20px; font-size:18px; color:#222222; font-weight:bold;}
  .blank_02 .txt_04 { padding-top:30px; padding-bottom:20px; font-size:16px; color:#222222; font-weight:bold;}
  /*사업현황*/
  .blank_03 { width:100%;}
  .blank_03 table { width:100%; margin:0 auto; border-top:2px solid #777777; border-bottom:1px solid #d0cbbb;}
  .blank_03 th { width:20%; height:50px; background-color:#f9f9f9; color:#222222; border-top:1px solid #d0cbbb; border-right:1px solid #d0cbbb; font-weight:bold; font-size:14px;}
  .blank_03 th:last-child { border-right:0px;}
  .blank_03 td { padding:10px; line-height:24px; border-top:1px solid #dddddd; border-left:0px; border-right:1px solid #d0cbbb; text-align:center; vertical-align:middle; font-size:14px; }
  .blank_03 td:last-child { border-right:0px;}
  .blank_03 .align { width:50%; text-align:left; padding-left:20px; vertical-align:middle; }
  .blank_03 .align img { padding-top:2px; padding-right:15px; vertical-align:middle; }
  .blank_03 .txt_003 { width:25%; text-align:center; font-weight:bold;}
  .blank_03 .heading_011 { padding-left:0px; padding-bottom:20px; font-weight:bold; font-size:22px; color:#1387ce; } 
  .blank_04 {padding-top: 40px; width:100%;}
  .blank_04 .heading_011 { padding-left:0px; padding-bottom:20px; font-weight:bold; font-size:22px; color:#1387ce; } 
  .blank_04 table { width:100%; margin:0 auto; border-top:2px solid #777777; border-bottom:1px solid #d0cbbb;}
  .blank_04 th { width:20%; height:50px; background-color:#f9f9f9; color:#222222; border-top:1px solid #d0cbbb; border-right:1px solid #d0cbbb; font-weight:bold; font-size:14px;}
  .blank_04 th:last-child { border-right:0px;}
  .blank_04 td { padding:10px; line-height:24px; border-top:1px solid #dddddd; border-left:0px; border-right:1px solid #d0cbbb; text-align:center; vertical-align:middle; font-size:14px; }
  .blank_04 td:last-child { border-right:0px;}
  .blank_04 .txt_003 { width:25%;}
  .blank_04 .align { width:50%; text-align:left; padding-left:20px; vertical-align:middle; }
  .blank_04 .align img { padding-top:2px; padding-right:15px; vertical-align:middle; }


  /*주차시설현황*/
  .blank_05 {padding-top: 40px; width:100%;}
  .blank_05 table { width:100%; margin:0 auto; border-top:2px solid #777777; border-bottom:1px solid #d0cbbb;}
  .blank_05 th { height:50px; background-color:#f9f9f9; color:#222222; border-top:1px solid #d0cbbb; border-right:1px solid #d0cbbb; font-weight:bold; font-size:14px;}
  .blank_05 th:last-child { border-right:0px;}
  .blank_05 td { padding:10px; line-height:24px; border-top:1px solid #dddddd; border-left:0px; border-right:1px solid #d0cbbb; text-align:center; vertical-align:middle; font-size:14px; }
  .blank_05 td:last-child { border-right:0px;}
  .blank_05 tr .txt_001 { width:20%;}
  .blank_05 tr .txt_002 { width:30%;}
  .blank_05 tr .txt_003 { width:15%;}
  .blank_05 tr .txt_004 { width:15%;}
  .blank_05 tr .txt_005 { width:20%;}
  .blank_05 .map_07 { margin-top:30px;}
  .blank_05 .heading_011 { padding-left:0px; padding-bottom:20px; font-weight:bold; font-size:22px; color:#f07100; }
  .blank_05 dl { width:100%; margin-top:50px; margin-bottom:50px; border-top:2px solid #777777; padding-bottom:30px; margin-bottom:50px;} 
  .blank_05 dt { float:left; display:block; padding-bottom:30px; padding-top:30px; padding-bottom:30px; border-top: 1px solid #dddddd;} 
  .blank_05 dd { float:left; display:block; padding-left:30px; padding-bottom:30px; padding-top:30px; padding-bottom:30px; border-top: 1px solid #dddddd;} 
  .blank_05 dd p { padding-top:20px; line-height:24px; } 
  .blank_05 dd h5 { font-size:18px; color:#222222; font-weight:bold; padding-top:30px;} 

  .blank_06 { width:100%;}
  .blank_06 .heading_011 { padding-left:0px; padding-bottom:20px; font-weight:bold; font-size:22px; color:#f07100; } 
  .blank_06 .txt_033 {font-weight:bold; font-size:14px; color:#222222; padding-bottom:20px; line-height:24px;}

  .blank_07 { width:100%;}
  .blank_06 table { width:100%; margin:0 auto; border-top:2px solid #777777; border-bottom:1px solid #d0cbbb;}
  .blank_06 th { width:20%; height:50px; background-color:#f9f9f9; color:#222222; border-top:1px solid #d0cbbb; border-right:1px solid #d0cbbb; font-weight:bold; font-size:14px;}
  .blank_06 th:last-child { border-right:0px;}
  .blank_06 td { padding:10px; line-height:24px; border-top:1px solid #dddddd; border-left:0px; border-right:1px solid #d0cbbb; text-align:center; vertical-align:middle; font-size:14px; }
  .blank_06 td:last-child { border-right:0px;}
  .blank_06 tr .txt_001 { width:20%;}
  .blank_06 tr .txt_002 { width:30%;}
  .blank_06 tr .txt_003 { width:15%;}
  .blank_06 tr .txt_004 { width:15%;}
  .blank_06 tr .txt_005 { width:20%;}
  .blank_06 .blank_p01 { padding-top:30px; line-height:24px;}
  .blank_06 h5 { font-size:18px; color:#222222; font-weight:bold; padding-top:30px; padding-bottom:20px;} 
  .blank_06 .align_01 { width:50%; text-align:left; padding-left:20px; vertical-align:middle; }
  .blank_06 .heading_0111 { padding-left:0px; padding-top: 50px; padding-bottom:20px; font-weight:bold; font-size:22px; color:#f07100; }
  .blank_06 .heading_0112 { padding-left:0px; padding-top: 50px; padding-bottom:20px; font-weight:bold; font-size:18px; color:#f07100; }
  .blank_06 h6 { font-size:14px; font-weight:bold; color:#222222; padding-bottom:10px;}
  .blank_077 { padding-top:20px;}

  .blank_08 { width:100%;}
  .blank_08 h3 { padding-left:0px; padding-top: 0px; padding-bottom:20px; font-weight:bold; font-size:22px; color:#d7177d; }
  .blank_08 .sub_txt02 { padding-top: 20px; padding-bottom:20px; font-size:14px; color:#777777; }
  .blank_08 .line_06 {width:100%; height:2px; background-color:#777777; margin-top:20px;}
  .blank_08 .camping_01 {padding-bottom:30px; height:560px; border-bottom: 1px solid #cccccc;}
  .blank_08 .camping_01 .camping_left {float: left; padding-top:50px;}
  .blank_08 .camping_01 .camping_left ul {padding-top:10px; padding-left:20px; list-style-image: url('/images/sub/ico_box.gif');}
  .blank_08 .camping_01 .camping_left ul li {height:30px; }
  .blank_08 .camping_01 .camping_right {float: right; padding-top:30px;}

  .blank_08 .camping_02 {padding-bottom:10px; }
  .blank_08 .camping_02 h4 {font-size:18px; font-weight:bold; color:#222222;}
  .blank_08 .camping_02 .p_01 {padding-top:15px;}

  .blank_08 .camping_02 .camping_img {padding-top:25px; margin-bottom:150px;}
  .blank_08 .camping_02 .camping_img .camping_img01 {float: left; width:25%;}
  .blank_08 .camping_02 .camping_img .camping_img02 {float: left; width:25%;}
  .blank_08 .camping_02 .camping_img .camping_img03 {float: left; width:25%;}
  .blank_08 .camping_02 .camping_img .camping_img04 {float: left; width:25%;}
  .blank_08 h5 {font-size:18px; font-weight:bold; color:#d7177d;}
  .blank_08 h6 { font-size:15px; font-weight:bold; color:#222222; padding-bottom:10px; padding-top:40px;}
  .blank_08 .tit_03 { font-size:15px; font-weight:bold; color:#222222; margin-bottom:20px; padding-top:10px;}
  .blank_08 .tit_033 { font-size:14px; font-weight:bold; color:#555555; margin-bottom:20px; padding-top:10px; padding-right:10px; text-align:right;}
  .blank_08 .h_01 {line-height:24px; }
  .blank_08 .line_04 {width:100%; height:1px; background-color:#dddddd;}
  .blank_08 .line_05 {width:100%; height:2px; background-color:#777777; margin-bottom:30px;}
  .blank_08 .line_07 {width:100%; height:1px; background-color:#dddddd; margin-bottom:30px;}
  .blank_08 .sub_txt01 {padding-top:20px; padding-bottom:20px; line-height:24px;}
  .blank_08 .f_01 {width:40%;} 
  .blank_08 .f_04 {width:11%;}
  .blank_08 .f_05 {width:17%;} 
  .blank_08 .f_02 {width:40%; text-align:left; padding-left:10px;} 
  .blank_08 table { width:100%; margin:0 auto; border-top:2px solid #777777; border-bottom:1px solid #d0cbbb; display:block; }
  .blank_08 img { width:100%; display:none; }
  .blank_08 th { width:16%; height:70px; background-color:#f9f9f9; color:#222222; line-height:22px; border-top:1px solid #d0cbbb; border-right:1px solid #d0cbbb; font-weight:bold; font-size:14px;}
  .blank_08 th:last-child { border-right:0px;}
  .blank_08 td { padding:10px; line-height:24px; border-top:1px solid #dddddd; border-left:0px; border-right:1px solid #d0cbbb; text-align:center; vertical-align:middle; font-size:14px; }
  .blank_08 td:last-child { border-right:0px;}

  .blank_09 table { width:100%; margin:0 auto; border-top:2px solid #777777; border-bottom:1px solid #d0cbbb;}
  .blank_09 th { height:70px; background-color:#f9f9f9; color:#222222; line-height:22px; border-top:1px solid #d0cbbb; border-right:1px solid #d0cbbb; font-weight:bold; font-size:14px;}
  .blank_09 th:last-child { border-right:0px;}
  .blank_09 td { padding:10px; line-height:24px; border-top:1px solid #dddddd; border-left:0px; border-right:1px solid #d0cbbb; text-align:center; vertical-align:middle; font-size:14px; }
  .blank_09 td:last-child { border-right:0px;}
  .blank_09 .alignleft {text-align:left;}


  .blank_12 {padding-top:20px; width:100%;}
  .blank_12 .concen {padding-top:20px; width:100%;}
  .blank_12 table { width:100%; margin:0 auto; border-top:2px solid #777777; border-bottom:1px solid #d0cbbb;}
  .blank_12 th { height:50px; background-color:#f9f9f9; border-top:1px solid #d0cbbb; border-right:1px solid #d0cbbb; font-weight:bold; font-size:14px;}
  .blank_12 th:last-child { border-right:0px;}
  .blank_12 td {  border-top:1px solid #d0cbbb; border-left:0px; border-right:1px solid #d0cbbb; font-size:14px; }
  .blank_12 td:last-child { border-right:0px; }
  .blank_12 .txt_03 { padding-top:30px; padding-bottom:20px; font-size:18px; color:#222222; font-weight:bold;}
  .blank_12 .txt_04 { padding-top:30px; padding-bottom:20px; font-size:16px; color:#222222; font-weight:bold;}



  /* CEO 인사말 */
  .ceo_img2 { width:100%; height:650px; background-size: contain; background-image:url('/images/sub/ceo_background.jpg');  vertical-align:top; }
  .ceo_img { width:100%; height:650px; background-size: contain; vertical-align:top; }
  .ceo_img .txt_01 {text-align:left; font-size:18px; font-weight:bold; color:#222222; padding-left:5px;}
  .ceo_img .txt_02 {text-align:left; font-size:14px; color:#555555; padding-top:20px; padding-left:5px; padding-bottom:20px; line-height:26px; word-break: keep-all;}

  /*광명동굴 부대시설*/
  .restaurant_info {
    background-color:#dae2fc;
    width:100%;
    height:170px;
    padding-top:20px;
    .box_01 { width:35%; float:left; text-align:right; vertical-align:middle; }
    .box_02 {
      width:60%;
      float:right;
      vertical-align:middle;
      margin-top:-10px;
      .btxt_01 {font-size:14px; line-height:36px; clear:both();}
      .btxt_02 {padding-top:10px;}
    }
  }

  .restaurant_info2 {
    background-color:#ffffff;
    width:100%;
    height:180px;
    padding-top:0px;
    .box_01 {width:30%; float:left; text-align:left; vertical-align:middle; }
    .box_02 {width:65%; float:left; text-align:left; vertical-align:middle; }
    .box_02 .btxt_01 {font-size:13px; line-height:36px; clear:both();}
    .box_02 .btxt_02 {padding-top:10px;}
  }

  .restaurant_info3 {
    background-color:#dae2fc; width:100%; height:120px; padding-top:30px; padding-bottom:20px; 
    .box_img {width:20%; float:left; text-align:right; vertical-align:middle; }
    .box_01 {width:75%; float:left; text-align:left; vertical-align:middle; }
    .box_01 .btxt_01 {font-size:16px; line-height:25px; clear:both(); padding-top:20px;padding-bottom:20px;padding-left:20px;}
    .box_01 .btxt_02 {padding-top:5px;}
    .box_02 {width:100%; float:left; text-align:left; vertical-align:middle;  }
  }
}
/********************** from main/contents.css ************************/
#fineDustForecast {
  height:36px;
  div {
    background: url("/images/mise.png") no-repeat;
    width: 167px;
    line-height:36px;
    color:#fff;
    font-size: 80%;
    text-indent:40px;
    margin-top: 10px;
    &.mise_02 {background-position:0 -37px;} /* 보통 */
    &.mise_03 {background-position:0 -74px;} /* 나쁨 */
    &.mise_04 {background-position:0 -111px;} /* 심각 */
    &.mise_05 {background-position:0 -148px;} /* 최악 */
  }
}
