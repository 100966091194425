#mainNaviMobile {
  @include show-for-only(small);
  div.cell {
  }
  a {
    margin: 3px;
    display: inline-block;
    width: 33px;
  }
  a.logo {
    width: 100%;
  }
}

.mobileShow {
	@include show-for-only(small);
}

.menuNaviMobile {
	@include show-for-only(small);
	position: absolute; 
	width: 100%;
	z-index: 9999;
	 
  li {
    border-top: 1px solid #ccc;
    background: #555555;
    a {
    	text-decoration:none !important; color: #ffffff;
    } 
    
    li {
    	margin-left: 0px;
    	border-top: 1px solid #ccc;
    	background: #ffffff;
    	font-size: 13px;
    	padding-left: 20px;
    	a {
    		background: url(/images/sub/bullet1.gif) no-repeat 0 7px;
	    	text-decoration:none !important; color: #343434;
	    	background-position: 0 50%;
    	}
    }
  }
}


#personalNavi {
  background: #fff;
  .grid-container{
    padding: 12px 1.5rem;
    padding-bottom: 0;
  }
  .logoNNotice {
    @include grid-col;
    @include breakpoint(medium) {
      @include grid-column(7);
    }
    
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .logo {
      margin-right: 25px;
      @include hide-for-only(small);
    }
    .notice {
      font-size: 14px;
      a {text-decoration:none !important; color: #4f565c;}
    }
  }
  @media #{$small-only} {
    .grid-container{
      background-color: #40362a;
    }
    .logoNNotice {
      padding: 0;
      margin-bottom: 2px;
      margin-top: -7px;
      .notice {
        font-size: 12px;
        a {color: #ffffff !important;}
      }
    }
  }
  .personalMenu {
    @include hide-for-only(small);
    position: relative;
    @include grid-col;
    @include breakpoint(medium) {
      @include grid-column(5);
    }
    margin-bottom: 0;
    .breadcrumbs {
      float: right;
      li {
        font-size: 80%;
        &:after {
          content: '';
        }
        a {
          color: #5d5855;
          font-weight: bold;
        }
      }
    }
  }
}

#snsNSearchNavi {
  @include hide-for-only(small);
  background: #403b38;
  .sns {
    float: left;
    height: 45px;
    line-height: 45px;
  }
  .breadcrumbs {
    margin: 14px 20px 0 0;
    padding-bottom: 0;
    li::after {
      color: #eaa8d6;
      content: ' | ';
    }
    li:last-child::after {
        content: '';
    }
    a {
      font-size: 12px;
      font-weight: 600;
      color: #eaa8d6;
      letter-spacing: -2px;
    }
  }

  .search {
    float: right;
    margin-left: 20px;
    .menu {
      #searchKeyword {
        width: 200px;
        height: 45px;
        background: #655659;
        border: 0;
        color: #ffffff;
      }
      button {
        width: 43px;
        height: 45px;
        text-indent: -999rem;
        background: #655659 url(/assets/img/btn_search.png) no-repeat 50% 50%;
      }
    }
  }
}

#mainNavi {
  @include hide-for-only(small);
  background: #fff;
  border-bottom: 1px solid #ccc;

  .mainMenu {
    position: relative;
    padding: 10px;
    a.logo {
      display: inline-block;
      margin: 8px;
      width: 154px;
    }
    nav.menu {
      display: inline;
      margin-left: 30px;
      a {
        width: 125px;
        display: inline-block;
        font-size: 18px;
        color: #36332a;
        margin-left: 5px;
        font-weight: 600;
      }
      a.on {
      	color: #602776;
      }
    }
    a.allMenu {
      top: 30px;
      right: 25px;
      font-size: 12px;
      color: #000200;
    }
  }
  .subMenu {
    border-top: 1px solid #ccc;
    background: #fff;
    position: absolute;
    width: 100%;
    display: none;
    z-index: 1000;
    nav {
      margin: 10px 0 10px 230px;
      ul {
        margin: 0;
        margin-left: 5px;
        width: 125px;
        list-style: none;
        display: inline-block;
        vertical-align: top;
        li {
          padding: 3px 0;
          a {
            font-size: 13px;
            color: #4b4e51;
            letter-spacing: -1.5px;
          }
        }
      }
    }
    border-bottom: 1px solid #ccc;
  }
}

#leftSubMenu {
	width: 222px;

  @include hide-for-only(small);
  
  @media screen and (max-width: 900px) {
    width: 100% !important;
  }

  h1 {
    height: 110px;
    background: url(/assets/img/leftmenu_titlebar.gif) no-repeat 50% 50%;
    font-size: 21px;
    line-height: 110px;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
    margin: 0;
  }
  ul {
    li {
      border: 1px solid #ccc;
      border-bottom: 0;
      &:first-child {
        border-top: 0;
      }
      &:last-child {
        border-bottom: 1px solid #ccc;
      }
      a {
        padding: 17px 15px;
        font-weight: 600;
        color: #000000;
        &:hover, &.on {
          color: #701e6a;
          background: url("/assets/img/dot_location_arrow.gif") no-repeat 95% 50%;
        }
      }
      ul.menu.nested {
        margin-left: 0;
        li {
          border: 0;
          border-top: 1px solid #ccc;
          a {
            font-size: 90%;
            letter-spacing: -1.2px;
            &:before {
              content: ' - ';
            }
          }
          ul.menu.nested {
            li {
              a {
                font-size: 80%;
                text-indent: .5rem;
                &:before {
                  content: ' · ';
                }
              }
            }
          }
        }
      }
    }
  }
  .callout {
    display: block;
    margin-top: 10px;
    background: #fbf5f9 url("/assets/img/dot_location_arrow.gif") no-repeat 95% 50%;
    letter-spacing: -1px;
    font-weight: 600;
    font-size: 13px;
    color: #342345;
    span {
      font-size: 16px;
    }
  }
}

@media #{$small-only} {
	.callout.warning {
		font-size: 12px;	
	}
}

#locationNavi {
  @include hide-for-only(small);
  ul {
    list-style: none;
    float: right;
    margin: 10px;
    li::after {
      content: '>';
    }
    li:last-child::after {
        content: '';
    }
    a {
      font-size: 12px;
      font-weight: 600;
      color: #666;
    }
  }
}

footer {
  background: #eeeeee;
  margin-top: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  h2 {
    display: none;
  }
  .cell {
    position: relative;
    .breadcrumbs {
      margin-top: 5px;
      margin-bottom: 5px;
      li::after {
        content: ' | ';
      }
      li:nth-last-child(1)::after {
          content: '';
      }
      a {
        font-size: 12px;
        // font-weight: 600;
        color: #6d6562;
        letter-spacing: -2px;
        font-family: 'Rix Gothic', 'Rix 고딕', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
      }
      
      .biz_popup li::after {
        content: ' ';
      }
    }
    address, .copy {
      font-style: normal;
      font-size: 12px;
      color: #a09b95;
    }
    @media #{$medium-up} {
      img.logo {
        position: absolute;
        top: 20px;
        right: 5px;
      }
      img.symbol {
        margin-top: 20px;
        margin-left: 45px;
      }
    }
    @media #{$small-only} {
      img.logo {
        margin-left: 27%;
      }
      img.symbol {
        display: none;
      }
      .breadcrumbs , address, .copy {
        text-align: center !important;
      }
      .breadcrumbs li {
      	float: none !important;
      	display: inline-flex;
      }
    }
  }
}
