@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

// using mixins
// @import 'util/mixins';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

// Layouts
@import 'global/layout';

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], 
textarea {
	padding: 0 0.3rem 0 0.3rem !important;
}
 
[type='password'] {
	height: 22px !important;
}

// Contents
main {
  padding-bottom: 4rem;
  h1 {
    padding-bottom: 15px;
    border-bottom: 1px solid #ccc;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  h1 small {
    font-weight: 500;
    margin-left: 1rem;
    color: #9e9e9e;
  } 
  h2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
    color: #4d3494;
    font-weight: 600;
  }
  h3 {
    font-weight: 600;
    color: #444444;
  }
  
  .line_04 {
  	width:100%;
  	height:1px;
  	background-color:#dddddd;
  }
  
  .culture {
  	font-size: 14px;
  	word-break: keep-all;
  	  h1 {
	    padding-bottom: 15px;
	    border-bottom: 1px solid #ccc;
	    margin-top: 1rem;
	    margin-bottom: 2rem;
	    font-weight: 600;
	    font-family: 'Rix Gothic', 'Rix 고딕', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
	    letter-spacing: -1px;
	    color: #000000;
	  }
	  h1 small {	
	    margin-left: 1rem;	
	    color: #9e9e9e;
	    font-size: 1.1rem;
	    font-weight: 600;
	    font-family: 'Rix Gothic', 'Rix 고딕', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
	    letter-spacing: -1px;
	  }
	  h2 {
	    margin-top: 40px;
	    color: #4d3494;
	    font-size: 1.6rem;
	    font-weight: 600;
	    font-family: 'Rix Gothic', 'Rix 고딕', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
	    letter-spacing: -1px;
	  }
	  h3 {
	    color: #444444;
	    font-size: 1.4rem;
	    margin-top: 40px;
	    margin-bottom: 20px;
	    font-weight: 600;
	    font-family: 'Rix Gothic', 'Rix 고딕', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
	    letter-spacing: -1px;
	  }
	  h4 {
	    color: #555555;
	    font-size: 1rem;
	    margin-top: 20px;
	    margin-bottom: 40px;
	    font-weight: 500;
	    letter-spacing: -1px;
	  }
	  h5 {
	    color: #b04baa;
	    font-size: 1.3rem;
	    margin-top: 40px;
	    margin-bottom: 10px;
	    font-weight: 600;
	    font-family: 'Rix Gothic', 'Rix 고딕', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
	    letter-spacing: -1px;
	  }
	  h6 {
	    color: #555555;
	    font-size: 1rem;
	    margin-top: 20px;
	    margin-bottom: 10px;
	    font-weight: 600;
	    font-family: 'Rix Gothic', 'Rix 고딕', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
	    letter-spacing: -1px;
	  }
	  
	  address {
	  	font-style: normal;
	  }
	  
	  .sfont {
	  	font-size:12px;
	  	text-align: right;
	  	margin: 0;
	  }
	  
	  .spacing-1{
	  	letter-spacing: -1px;
	  }
  }
  
  table.view {
  	word-break: keep-all;
    border-top: 2px solid #000;
    caption {
      text-align: left;
      font-size: 22px;
      color: #ab4ea6;
      margin-bottom: 30px;
    }
    th, td {
      text-align: center;
      font-size: 14px;
      border: 1px solid #ccc;
      background: #eee;
      &:first-child {
        border-left: 0;
      }
      &:last-child {
        border-right: 0;
      }
    }
    td {
      background: #fff;
    }
    @media #{$small-only} {
      caption {
        font-size: 14px;
        margin-bottom: 0px;
      }
      th, td {
        font-size: 12px;
      }
    }
  }
}


section.circleBoard {
  @include grid-row();
  dl {
      float: left !important;
    @include grid-col;
    @include breakpoint(medium) {
      @include grid-column(4);
    }
    @include breakpoint(small) {
      @include grid-column(6);
    }

    margin-top: 1%;
    dt {
      font-size: 130%;
    }
    dt , dd {
      text-align: center;
    }
    dd span {
      display: inline-block;
      width: 220px;
      height: 220px;
      position: relative;
      overflow: hidden;
      border-radius: 50%;
      img {
        display: inline;
        margin: 0 auto;
        height: 100%;
        width: auto;
      }
    }
    @media #{$small-only} {
      dt {
        font-size: 100%;
      }
      dd span {
        width: 150px;
        height: 150px;
      }
    }
  }
}

section.phoneContact {
  margin-top: 3rem;
  text-align: center;
  font-size: 14px;
  p {
    margin-top: 1rem;
  }
  
  a {
  	color: #555555;
  }
}

// old gmuc contents
@import 'global/contents';
@import 'global/util';

.backslash {
  background-image: url(/images/backslash.png) !important;
  background-size: 100% 100% !important;	
  text-align: left !important;
}

.ci_area {
  width:100%;
  float:left;
  .detail_left {width:50%;float:left;}
  p {
    text-align:center;
    margin-bottom:30px;
    img {
      max-width:100%;
    }
  }
  .detail_right {
    width:50%;
    float:left;
    margin-bottom:30px;
  }
  .ci_title {
    clear:both;
    font-weight:bold;
    font-size:14px;
    width:100%;
    float:left;
  }
  .ci_b_title {
    clear:both;
    padding-top:30px;
    font-size:30px;
    float:left;
    width:100%;
    letter-spacing:-1px;
  }
  .download1 {
    background:#93519a;
    color:#fff;
    padding:5px 10px 5px 10px;
    border-radius:5px;
    border:1px solid #fff;
  }
  .download2 {
    background:#d92686;
    color:#fff;
    padding:5px 10px 5px 10px;
    border-radius:5px;
    border:1px solid #fff;
  }
  .download3 {
    background:#f5821f;
    color:#fff;
    padding:5px 10px 5px 10px;
    border-radius:5px;
    border:1px solid #fff;
  }
  .dec {width:100%;line-height:150%;font-size:13px;padding-bottom:10px;}
  .btn_area {float:left;margin-bottom:20px;width:33.33%;text-align:center;}
}

/********************************* footer *********************************/
.biz_popup {
  background:#eee;
  position:absolute;
  left:0;
  bottom:20px;
  z-index:9999;
  width:250px;
  border:3px solid #258fd0;
  border-radius:15px;

  p {
    font-size:1.2em;
    font-weight:800;
    background:#258fd0;
    color:#fff;
    padding:10px;
    span {
      float:right;
      font-size:0.7em;
    }
  }
  ul {
    padding:15px;
    float:left;
    li {
      height:25px;
      line-height:25px;
    }
  }
}
/**************************** 기본 모양 *********************************/
main article {
  h4 {
    font-size: 100%;
  }
  .callout {
    h5 {
      font-size: 100%;
    }
    ul {
      margin-left: 1.5rem;
      ul {
        list-style: none;
        margin-left: 1rem;
        li:before {
          content: '- ';
        }
      }
    }

    dl.trace {
    }
    dl.trace dd {
      display: inline;
    }
    dl.trace dd:after {
      content: ' → ';
      font-weight: 600;
      font-size: 150%;
      color: #00f;
    }
    dl.trace dd:last-child:after {
      content: '';
    }
  }
  /***************************** 아마도 brief 로... **************************/
  dl.callout {
    dt {
      white-space: pre;
    }
    dd {
      margin-left: 2%;
      margin-bottom: 2%;
    }
    dt:after {
      content: ':';
    }
    ul {
      list-style: none;
      margin-left: 0;
    }
    ul li:before {
      content: '- ';
    }
  }
  /***************************** 아마도 billboard 로... **************************/
  dl.callout.primary {
    padding: 2% 5%;
    > dt ,
    > dd {
      width: 15%;
      display: inline-block;
      vertical-align: middle;
    }
    dt:after {
      content: '';
    }
    > dd {
      width: 80%;
      word-break: keep-all;
    }
    > dd dl {
      dt {
        display: inline-block;
        border: 1px solid #ccc;
        padding: 2px 1rem;
        background: #9966aa;
        color: #fff;
      }
      dd.important {
        font-weight: 600;
      }
    }
  }
  /***************************** 아마도 billboard 로... **************************/
  .callout.primary {
    position: relative;
  }
  .callout.primary a {
    position: absolute;
    right: 2%;
    top: 10%;
    
    @media #{$small-only} {
    	width: 35%;
    }
    
  }
  
  .callout.primary h2 {
  	font-size:18px;
  	font-weight: 600;
  	color: #555555;
  }
}
/***************************** circleBoard처럼 변경 **************************/
section.imageBoard {
	margin-top: 1rem;
  p {
    width: 49%;
    display: inline-block;
	font-weight: bold;
	font-size: 130%;
	text-align:center;
	@media #{$small-only} {
      	font-size: 100%;
    }
  }
  .block {
  	display: block;
  }
  
  p img {
    border-radius: 1rem;
    max-height: 205px;
  }
  h3 {
    font-size: 100%;
    margin-top: 1rem;
  }
  div {
    margin-bottom: 1rem;
    padding-left: 1.5rem;
    word-break: keep-all;
  }
  .h5 {
  		color: #b04baa;
	    font-size: 24px;
	    margin-top: 40px;
	    margin-bottom: 10px;
	    font-weight: 600;
	    font-family: 'Rix Gothic', 'Rix 고딕', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
	    letter-spacing: -1px;
  }
}


/***************************** table.view 추가 **************************/
table.view td.contents {
  text-align: left;
  dl {
    margin-bottom: 0;
    dd.warning {
      margin-top: 0.5rem;
      font-size: 90%;
      padding-left: 1rem;
    }
  }
  ul {
    margin-bottom: 0;
  }
}


/*************************** tab_areaBlue **************************/
.tab_areaBlue {
  width:94%;
  position:relative;
  margin:-15px;
  clear:both;
  word-break: break-all;

  ul {
    list-style: none;
    width:110%;
    li {
      display: inline;
      a {
        display: block;
        width: 19%;
        text-align:center;
        margin-top:-1px;
        float:left;
        padding:8px 0 8px 0;
        border:1px solid #ccc;
        margin-left:-1px;
        font-size: 80%;
		
		@media #{$small-only} {
	      	height: 50px;
	      }
		
        &.on {
          font-weight:bold;
          background:#36a9f0;
          border:1px solid #89ac34;

          color:#fff;
        }
      }
    }
  }
}

.tab_area {
  width:94%;
  position:relative;
  margin:-15px;
  clear:both;
  word-break: break-all;

  ul {list-style: none;width:106%}
  li {
    display: inline;
    a {
      display: block;
      width: 19%;
      text-align:center;
      margin-top:-1px;
      float:left;
      padding:8px 0 8px 0;
      border:1px solid #ccc;
      margin-left:-1px;
      font-size: 80%;
      
      @media #{$small-only} {
      	height: 50px;
      }

      &.on {font-weight:bold;
        background:#36a9f0;
        border:1px solid #89ac34;
        color:#fff;
      }
    }
  }
  
  .parking li a{
  	width: 25%;
  }
  
}

.tabContents {
  padding-top:30px;
  clear:both;
  display: none;
  h2 {
    font-size: 130%;
    color: #f07100;
  }
  ol {
    margin: 0 0 0 1.25rem;
    li {
      font-size: 18px;
      font-weight: 600;
      margin: 2rem 1rem;
      p {
        margin: 5px 0 5px 0; 
        font-weight: 500;
        font-size: 13px;
      }
      small {
        font-size: 80%;
        margin-left: 3rem;
      }
      ol {
        li {
          margin: 5px;
          font-weight: 500;
          font-size: 13px;
          ol {
            margin: 0 0 0 1.25rem;
            li {
            	ol {
					margin: 0;            	
            	}
            }
            
          }
          p {
            font-size: 100%;
          }
          ol li{
            font-size: 100%;
          }
        }
      }
      td {
      	font-weight: 500;
      }
    }
  }
  
  ol .round {
  	margin: 0 0 0 -0.8rem !important;
  }
  ol .rightBrace {
  	margin: 0 0 0 -0.6rem !important;
  }
}

ol.rightBrace {
  list-style: none !important;
  margin: 0px;
  &:first-child {
    counter-reset: customlistcounter;
  }
  > li {
    counter-increment: customlistcounter;
    &:before {
      content: counter(customlistcounter) ') '
    }
  }
  
  ol { 
  	margin-left: 1.5rem !important;
  }
}

@media #{$small-only} {
	.mPrivacy{
		margin-left: -20px !important;
	}
}
@media all and (max-width:405px) {
	.mPrivacyA{
		display: block;
	}
}
@media all and (max-width:485px) {
	.mPrivacyB{
		display: block;
	}
}
/********************************** golf 이용약관 **********************************/
div.golf_box {
  border: 1px solid #ccc;
  padding: 1rem;
  h2 {
    font-family: 'Nanum Gothic', '나눔고딕', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
    font-size: 18px;
    font-weight: 600;
	margin: 0;
	padding: 25px 0 15px 0;
	color: #000000;
	letter-spacing: 0px;
  }
  h3 {
    font-family: 'Nanum Gothic', '나눔고딕', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
	margin: 0;
	padding: 15px 0 10px 0;
	color: #555555;
	letter-spacing: 0px;
  }
}
ol.hangul {
  list-style: hangul !important;
}
ol.eng {
  list-style: lower-alpha !important;
}
ol.round {
  list-style: none !important;
  margin: 0px;
}
ol.dash {
  margin-left: 0px;
  list-style: none !important;
	li:before {
	  content: '- ';
	}
}
p.ps {
  margin-top:30px;
  text-align:right;
}

/********************************** from scenter_calendar **********************************/
#script-warning {
  display: none;
  background: #eee;
  border-bottom: 1px solid #ddd;
  padding: 0 10px;
  line-height: 40px;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  color: red;
}

#loading {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
}

#calendar {
  max-width: 900px;
  margin: 40px auto;
  padding: 0 10px;
}

table.tb_list3 {
  thead th {
    text-align: center;
  }
  th , td {
  }
  td.title {
    text-align: left;
  }
}

#calendar .fc-center h2{
	margin:0px;
}
