@charset "utf-8";
/** borad **/
.btn_w { display: inline-block; min-width: 40px; padding: 5px 12px; border: 1px solid #b9b9b9; background: #fff url(/images/sub/btn_w.gif) repeat-x left bottom; color: #525252; text-align: center; font-weight: bold; font-size: 1em; cursor: pointer; }
.btn_b { display: inline-block; min-width: 40px; padding: 5px 12px; border: 1px solid #006ca4; background: #2173bf url(/images/sub/btn_b.gif) repeat-x left bottom; color: #fff !important; text-align: center; font-weight: bold; font-size: 1em; cursor: pointer; }
.btn_b.small, .btn_w.small {min-width:40px;padding:1px 12px;font-size:0.9em; height: 22px;}

div.search { margin-bottom: 20px; padding: 12px; border: 1px solid #cbcbcb; background: #f7f7f7; text-align: center; }
div.search select { padding: 5px; vertical-align: middle; }
div.search input[type=text] { width: 30%; height: 24px; margin: 0 5px; border: 1px solid #cbcbcb; }
div.generalboard .board_lock { margin-right: 5px; vertical-align: middle; }

table.general_board {
  margin-bottom: 20px;
  font-size: 14px;

  caption { text-align: right; font-size: 80%; padding: 0;}
  th, td {
    &:first-child {border-left: none;}
    &:last-child {border-right: none;}
  }

  thead {
    th {
      background: #eee;
      border-top: 2px solid #2173bf;
      border-bottom: 1px solid #2173bf;
      text-align: center;
    }
  }

  tbody {
    tr {
      &:hover {
        background: #f9edef; 
      }
      th, td {
        border-bottom: 1px solid #cbcbcb;
        text-align: center;
        img { margin-left: 3px; }
        a {
          &.secret {
            color: #833;
          }
          &.mine {
            color: #555555;
          }
        }
        &.abbreviate a { display: block; white-space: nowrap; text-overflow: ellipsis; }
        &.title{ text-align: left; overflow: hidden !important; white-space: nowrap !important; text-overflow: ellipsis !important; max-width: 200px;}
      }
    }
  }
  .view {
    background: #f8f8f8;
    th { width: 15%; }
    td { width: 35%; text-align: left; }
  }

  .view2 {
    background: #f8f8f8;
    td { text-align: left; }
  }
  .view_contents { padding: 50px 30px; background: #fff; line-height: 2em; }
  .reply td { padding: 50px 30px; border-top: 2px solid #2173bf; background: #f8f8f8; text-align: left; }
}

table.general_board.write {
  border-top: 2px solid #2173bf;
  font-size: 14px;

  caption { display: none; }
  th, td {
    border: 1px solid #c5c5c5;
    font-size: 14px;
    &:first-child { border-left: none; }
    &:last-child { border-right: none; }
  }
  th { background: #f8f8f8; }
  td {
    background: #fff;
    text-align: left;
    padding-left: 10px;
  }
  input, select {
    width: auto;
    display: inline-block;
    margin: 0;
    font-size: 14px;
  }
  select {
    height: 22px;
    padding-top: 0;
    padding-bottom: 0;
  }
  input[type=text] { height: 22px; border: 1px solid #c5c5c5; font-size: 14px; }
  input[type=file] { height: 22px; font-size: 14px; }
}
#tx_emoticon_menu td {padding-left: 0 !important;padding-right: 0px !important;}

nav.component {
  text-align: right;
}



ul.general_board2 { border-top: 2px solid #2173bf; margin-bottom: 50px; }
ul.general_board2 dl { border-bottom: 1px solid #cbcbcb; }
ul.general_board2 dl { border-bottom: 1px solid #cbcbcb; }
ul.general_board2 dl dt { padding: 15px; font-size: 1.05em; }
ul.general_board2 dl dt span { margin-right: 5px; color: #2173bf; font-weight: 600; }
ul.general_board2 dl dd { padding: 15px 35px 15px 35px; border-top: 1px solid #e2e2e2;  background: #f9f9f9; }
div.generalboard div.reply2 { padding: 30px; border: 1px solid #cbcbcb; background: #f7f7f7; }
div.generalboard div.reply2 p.tit { padding-bottom: 15px; font-weight: 600; }
div.generalboard div.reply2 dl { padding: 15px 0; border-top: 1px dotted #cbcbcb; }
div.generalboard div.reply2 dl.last { margin-bottom: 20px; }
div.generalboard div.reply2 dt { font-weight: 600; margin-bottom: 5px; }
div.generalboard div.reply2 dd { font-size: 0.95em; }
div.generalboard div.reply2 textarea { width: 91%; margin-right: 5px; border: 1px solid #cbcbcb; }
div.generalboard div.reply2 .btn_b { text-align: right; vertical-align: middle; }
div.gallery { margin-bottom: 50px; }
div.gallery p.photo { background: #F7F7F7; }
div.gallery p.photo a { display: block; }
div.gallery p.photo img { width: 100%; }
div.gallery .list ul li { overflow: hidden; padding: 20px 20px; border-bottom: 1px dotted #ccc; }
div.gallery .list p.photo { float: left; width: 205px; min-height: 140px; margin-right: 25px; }
div.gallery .list div p:first-child { margin-top: 0; font-weight: 600; font-size: 1.25em; }
div.gallery .list div { position: relative; padding-bottom: 30px; padding-left: 230px; }
div.gallery .list div p { margin-top: 15px; }
div.gallery .list div p.date { position: absolute; bottom: 0; }
div.gallery .window ul { overflow: hidden; }
div.gallery .window li { float: left; margin-bottom: 20px; border: 1px solid #dddddd; background: url(/images/programs/gallery_bg.gif) repeat-x left bottom; }
div.gallery .window.urn li { padding-bottom: 5%; }
div.gallery .window p.photo { overflow: hidden; min-height: 168px; margin: 10px; border: 1px solid #cccccc; }
div.gallery .window p { margin: 0 10px; }
div.gallery .window p.photo img { height: 168px; }
div.gallery .window p.tit { margin-top: 10px; font-weight: 600; font-size: 1.05em; }
div.gallery .window p.date { margin-top: 10px; margin-bottom: 15px; font-size: 0.95em; text-align: right; }
div.gallery .ebook ul { overflow: hidden; padding: 20px 0; border-bottom: 1px dotted #ccc; }
div.gallery .ebook ul li {  float: left; width: 46%; margin: 2%}
/*div.gallery .ebook ul li p.photo { float: left; width: 48%; min-height: 251px; margin-right: 3%; border: 1px solid #cccccc; }
div.gallery .ebook ul li div.ebook_con { float: left; width: 45%; }*/
div.gallery .ebook ul li div.ebook_con p.cate { font-weight: bold }
div.gallery .ebook ul li div.ebook_con p.cate span.cate_type01{ color: #244086; }
div.gallery .ebook ul li div.ebook_con p.cate span.cate_type02{ color: #4e395b; }
div.gallery .ebook ul li div.ebook_con p.cate span.cate_type03{ color: #20554e; }
div.gallery .ebook ul li div.ebook_con p.cate span.cate_type99{ color: #666; }
div.gallery .ebook ul li p.tit { margin-top: 0; font-weight: 600; font-size: 1.25em; }
div.gallery .ebook ul li p.tit > a { display: block; text-overflow: ellipsis; white-space: nowrap; overflow: hidden }
div.gallery .ebook ul li p.date { margin-top: 5px; }
div.gallery .ebook ul li p.btn { margin-top: 15px; }

div.paging {
  text-align: center;
  margin-top: 0.5rem;
  ul.pagination {
    li {
    	display: inline-block !important;
    	margin: 0;
    	@media #{$small-only} {
    		a {
		        width: 20px !important; font-size: 11px;
		      } 
    	}
      a {
        border-radius: 50%; width: 30px; height: 30px; border: 1px solid #cbcbcb; background: #fff; padding: 0; text-align: center;
        line-height: 30px;
      }
      &.on a {
        border: 1px solid #006ca4; background: #2173bf; color: #fff; font-weight: bold; text-decoration: underline;
      }
    }
  }
}

ul.other_con { margin-top: 20px; border-bottom: 1px solid #cbcbcb; }
ul.other_con li { overflow: hidden; border-top: 1px solid #cbcbcb; line-height: 3.5em; }
ul.other_con li span { width: 20%; margin-right: 10px; background: #f8f8f8; font-weight: bold; text-align: center; }
ul.other_con li span, .other_con li a { display: block; float: left; }
ul.other_con li a {overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
div.nocontens { padding: 40px 10px; border-top: 2px solid #2173bf; border-bottom: 1px solid #cbcbcb; text-align: center; }
div.control_pop div.top { overflow: hidden; height: 70px; background: #2173bf; }
div.control_pop div.top p { float: left; }
div.control_pop div.top p.tit { float: left; margin-top: 25px; padding-left: 20px; font-size: 1.5em; color: #fff; font-weight: 600; }
div.control_pop div.top p.close { float: right; margin: 0; padding: 19px 20px; background: #00468d; cursor: pointer }
div.control_pop div.graph { margin: 30px; }

/*organization*/
.organ_guide { position: absolute; right: 0; padding: 3px 20px; font-size: 11px; color: #fff; border-radius: 20px; background: #2173bf; }
.organ { width: 790px; margin: 0px auto; }
.organ .ceo { text-align: center; width: 185px; height: 131px; margin: 0 auto; padding-top: 54px; font-size: 24px; font-weight: 700; line-height: 40px; background: url(/images/sub/organ_bg1.giff) no-repeat center; color: #1a367b; }
.organ .ceo span.name { font-size: 14px; font-weight: bold; color: #373839; line-height: 20px; }
.organ .ceo span.num { font-size: 12px; font-weight: normal; color: #373839; line-height: 20px; }
.organ .step2>li { width: 174px; background: url(/images/sub/organ_line1.giff) repeat-y center top; }
.organ .step2>li>p { text-align: center; width: 110px; height: 110px; margin: 0 auto; line-height: 110px; font-size: 15px; color: #4e395b; font-weight: 600; background: url(/images/sub/organ_bg2.giff) no-repeat; }
.organ .step2>li>p a { padding-right: 20px; background: url(/images/sub/organ_link2.giff) no-repeat right center; }
.organ .step2 li p a { color: #4e395b; }
.organ .step2 .box { margin-top: 15px; }
.organ .step2 .box li { margin-bottom: 15px; }
.organ .box { padding-bottom: 15px; border: 1px solid #ccc; background: #f9f9f9; }
.organ .box a { padding-right: 19px; background: url(/images/sub/organ_link0.giff) no-repeat right 4px; }
.organ .box li { margin-left: 17px; font-size: 12px; }
.organ .box li:first-child { margin: 15px 17px 10px; padding-bottom: 10px; border-bottom: 1px solid #ccc; text-align: center; }
.organ .box li:first-child span { font-size: 13px; font-weight: 600; }
.organ .step3>li>p { width: 110px; height: 110px; margin: 0 auto; text-align: center; color: #fff; font-size: 15px; font-weight: 600; }
.organ .step3>li>p span { display: block; padding-top: 53px; }
.organ .step3>li.culture>p { background: url(/images/sub/organ_bg3_1.giff) no-repeat; }
.organ .step3>li.welfare>p { background: url(/images/sub/organ_bg3_2.giff) no-repeat; }
.organ .step3>li.traffic>p { background: url(/images/sub/organ_bg3_4.giff) no-repeat; }
.organ .step3>li.safety>p { background: url(/images/sub/organ_bg3_3.giff) no-repeat; }
.organ .step3>li.manage>p { background: url(/images/sub/organ_bg3_5.giff) no-repeat; }
.organ .step3>li.future>p { background: url(/images/sub/organ_bg3_6.giff) no-repeat; }
.organ .step3 .box  { min-height: 110px;}
.organ .step3>li a { padding-right: 19px; }
.organ .step3>li.culture a { background: url(/images/sub/organ_link1.giff) no-repeat right 4px; }
.organ .step3>li.welfare a { background: url(/images/sub/organ_link2.giff) no-repeat right 4px; }
.organ .step3>li.traffic a { background: url(/images/sub/organ_link3.giff) no-repeat right 4px; }
.organ .step3>li.safety a { background: url(/images/sub/organ_link4.giff) no-repeat right 4px; }
.organ .step3>li.manage a { background: url(/images/sub/organ_link5.giff) no-repeat right 4px; }
.organ_btn { margin: 20px auto; padding: 8px; width: 230px; background: #f5f5f5; border: 1px solid #dadada; border-radius: 50px;}
.organ_btn a { display: inline-block; padding: 15px; width: 200px; text-align: center; font-size: 16px; font-weight: bold; color: #fff; border-radius: 30px; background: #2173bf; }
.organ_btn a:hover, .organ_btn a:active { background: #6755a4; }

/*행사*/
.citizen_event .icon_list { overflow: hidden; margin-bottom: 10px; }
.citizen_event .icon_list li { float: left; margin-right: 10px; }
.citizen_event .box { border: 1px solid #e0dcd0; background: #fcfaf3; }
.citizen_event .box .month { position: relative; text-align: center; padding: 45px 0 35px 0; font-size: 50px; color: #3a3a3a; font-weight: 700; font-family: 'Nanum Myeongjo','나눔명조'; }
/*.citizen_event .box .month a.prev { position: absolute; left: 50%; margin-left: -22%; }
.citizen_event .box .month a.next { margin-left: 3%; }*/
.citizen_event .box .month a.prev { position: absolute; left: 50%; top: 40px; margin-left: -130px; }
.citizen_event .box .month a.next { position: absolute; right: 50%; top: 40px; margin-right: -130px; }
.citizen_event .box table { width: 90%; margin: 0 auto; table-layout: fixed; }
.citizen_event .box table th, .citizen_event .box table td { border-top: 1px solid #d0cbbb; border-left: 1px solid #d0cbbb; border-right: 1px solid #d0cbbb; }
.citizen_event .box table th { padding: 5px; font-weight: 600; font-size: 16px; }
.citizen_event .box table td { text-align: right; vertical-align: top; height: 60px; padding: 10px; font-size: 16px; }
.citizen_event .box table td:first-child{border-left: none}
.citizen_event .box table td:hover, .citizen_event .box table td:active, .citizen_event .box table td.on { background: #fff; cursor: pointer }
.citizen_event .box table td p { text-align: left; }
.citizen_event .box table .sun { border-left: 0; color: #d52a2a; }
.citizen_event .box table .sat { border-right: 0; color: #0a6ea1; }
.citizen_event .box table .today { color: #7926a9; }
.citizen_event .box table .today span { font-size: 12px; }
.citizen_event .event_list .tit { padding-bottom: 12px; margin-bottom: 15px; border-bottom: 1px dotted #ddd; font-size: 16px; font-weight: 600; }
.citizen_event .event_list>div .tit img { vertical-align: middle; }
.citizen_event .event_list>div div.con { overflow: hidden }
.citizen_event .event_list>div div.con p.img img { width: 100%; height: 140px; }
/*.citizen_event .event_list>div ul { margin-left: 5px; }
.citizen_event .event_list>div li { padding-left: 9px; background: url(/images/sub/bar_blet.giff) no-repeat left 9px; }
.citizen_event .event_list>div li:first-child { padding: 0; background: none; margin-bottom: 6px; }*/

#list_tab { width: 90%; margin: 0 auto 35px; border: 1px solid #c7c6c6; background: #fafafa; }
#list_tab ul { overflow: hidden; padding: 25px 30px; }
#list_tab li { float: left; width: 25%; margin-bottom: 4px; }
#list_tab li a { display: block; padding-left: 10px; background: url(/images/sub/bar_blet.giff) no-repeat left 8px; }

/* 뉴스레터신청 */
.mail_top p.logo { padding: 15px }
.mail_top p.tit { padding: 15px 15px 12px; background: #2173bf; font-weight: bold; font-size: 19px; color: #fff; }
.mail_refuse p.txt{ padding: 30px; text-align: center; font-size: 15px; }
.mail_refuse .caution { margin: 0px 10px 20px; padding: 20px; padding-top: 15px; background: #f5f5f5; border: 1px solid #ccc; }
.mail_refuse .caution li { margin-top: 5px; padding-left: 9px; background: url(/images/sub/ssssblet.giff) no-repeat left 10px; }

@media all and (min-width: 1025px) {
	div.gallery div.list p.photo img { width: 205px; height: 140px; }
	div.gallery .window li { width: 31%; margin-left: 1%; margin-right: 1%; }

	div.gallery .ebook ul li p.photo { float: left; width: 180px; min-height: 240px; margin-right: 15px; border: 1px solid #cccccc; }
	div.gallery .ebook ul li p.photo img { width: 180px; height: 240px; }
	div.gallery .ebook ul li div.ebook_con { float: left; width: 45%; }

	.organ { position: relative; background: url(/images/sub/organ_line.giff) no-repeat 62px 120px; }
	.organ .step2_1 { position: absolute; top: 47px; left: 115px; }
	.organ .step2_2 { position: absolute; top: 47px; right: 115px; }
	.organ .step2_1 .box { position: absolute; width: 150px; left: -140px; top: -30px; }
	.organ .step3 { overflow: hidden; margin-top: 77px; }
	.organ .step3>li { float: left; margin-left: 8px; }
	.organ .step3>li:first-child { margin-left: 0; }
	.organ .step3 .box { margin-top: 13px; width: 122px; height: 230px; }
	.organ .step3 .future .box { margin-top: 123px }
	.organ .step3>li ul.box li.line2_60 { padding-top: 2px; width: 60%; line-height: 14px; }
	.organ .step3>li ul.box li.line2_80 { padding-top: 2px; width: 80%; line-height: 14px; }
}

/*1024x768*/
@media all and (max-width: 1025px) {
	div.gallery .ebook ul li p.photo { float: none; }
	div.gallery .window li { width: 45%; margin-left: 2%; margin-right: 2%; }
	/*div.gallery .window p.photo img { height: 168px; }*/

	.organ { width: 50%; margin: 0 auto; padding-top: 50px; }
	.organ .step2 { overflow: hidden; margin-top: 10px; margin-bottom: 20px; }
	.organ .step2 .step2_1 { float: left; }
	.organ .step2 .step2_2 { float: right; width: 110px; }
	.organ .step3 .box { width: 50%; }
	.organ .step3>li { overflow: hidden; margin-bottom: 20px; }
	.organ .step3>li p { float: left; }
	.organ .step3>li ul { float: right; }
}

/*TABLET*/
@media all and (max-width: 769px) { 
	div.generalboard div.reply2 textarea { width: 80%; }
	div.gallery .window li { margin-left: 15px; }
}

/*MOBILE*/
@media all and (max-width: 640px) { 
	div.gallery .window p.date, div.gallery .ebook p { text-align: center; }
	div.gallery .list div { overflow: hidden; width: 99%; padding-top: 15px; }
	div.gallery .list p.photo { width: 100%; margin-top: 25px; }
	div.gallery .list div { padding-left: 0; }
	div.gallery .window li { float: none; width: 99%; margin-bottom: 15px;  margin-left: 0; text-align: center; }
	div.gallery .window p.photo img { height: auto; }
	div.gallery .ebook ul { padding: 0; border-bottom: none; }
	div.gallery .ebook ul li { float: left; width: 100%; padding: 20px 0; border-bottom: 1px dotted #ccc; }
	div.gallery .ebook ul li p.photo { float: none; width: 100%; min-height: auto; margin-bottom: 10px; }
	.general_board #bbs_no, .general_board .bbs_no,
	.general_board #bbs_dt, .general_board .bbs_dt,
	.general_board #bbs_hit, .general_board .bbs_hit
	{ display: none; }

	.organ { width: 60%; }

	.citizen_event .event_list>div { padding: 15px 10px; border-bottom: 1px solid #ccc; }
	.citizen_event .event_list>div ul { margin-top: 10px; }
	.citizen_event .event_list>div>img { display: none; }
	.citizen_event .event_list>div .tit span { display: block; margin-top: 3px; padding-left: 25px; }
	.citizen_event .event_list>div div.con p.img { display: none; }
	
	.tabmenu ul {
		margin-left: 0px;
	}
	#port1_a, #port2_a, #port3_a, #port4_a, #port5_a {
		background-size: 60px !important;
		width: 60px !important;
		height: 60px !important;
		
		&:hover, &.over {
			background-size: 60px !important;
			height: 60px !important;
			width: 60px !important;
		}
	}
	
	.list_sy6 li{
	 	dl {
			width: 100% !important;
	 	}
	 	p {
	 		background:none !important;
	 	}
	}
	
	.ty2_ico1 {
		background-size: 130px !important;
		width: 70px !important;
		height: 70px !important;
	}
	.ty2_ico2 {
	    background-size: 130px !important;
	    background-position: 10px !important;
	    height: 70px !important;
	    width: 70px !important;
	}
	.ty2_ico3 {
		background-size: 130px !important;
	    background-position: 0 -170px !important;
	    height: 70px !important;
	    width: 70px !important;
	}
	.ty2_ico4 {
		background-size: 130px !important;
	    width: 70px !important;
	    height: 70px !important;
	    background-position: -80px 1px !important
	}
	.ty2_ico5 {
	    background-size: 130px !important;
	    background-position: -80px -85px !important;
	    height: 70px !important;
	    width: 70px !important;
	}
	
}


@media all and (min-width: 640px) { 
	.citizen_event .event_list>div { overflow: hidden; padding: 25px 35px; margin-top: 15px; border: 1px solid #ccc; }
	.citizen_event .event_list>div div.con div.txt { float: left; width: 64%; }
	.citizen_event .event_list>div div.con p.img { float: right; width: 35%; }
}

@media all and (min-width: 480px) { 
	.citizen_event .calendar { padding: 30px 30px 15px; border: 1px solid #d0cbbb; background: #f1eee5 url(/images/sub/calendar_bg.giff) no-repeat center bottom; }
	.citizen_event .calendar table { margin-bottom: 20px; }
	.citizen_event .calendar .txt { padding-top: 15px; text-align: right; }
}

/*MOBILE_min*/
@media all and (max-width: 480px) { 
	.organ { width: 100%; }
	.citizen_event .box .month { font-size: 40px; }
	.citizen_event .box .month a.prev { margin-left: -95px; }
	.citizen_event .box .month a.next { margin-right: -95px; }
	.citizen_event .calendar .txt { display: none; }
}


/* .tb_list3 {width: 953px; color: #393939; font-size: 13px; }
	.tb_list3 td {padding: 14px 0 13px 0; border-bottom: 1px solid #9e9da3; color: #393939; font-weight: bold; line-height: 20px; }
	.tb_list3 td a {color: #195a8b; }
	.tb_list3 td a:hover {text-decoration: underline; }
	.tb_list3 td.icon {padding-left: 20px; }
	.tb_list3 tr.ans td {font-weight: normal; background: #f3f3f3; }

 */
 
 .faq{ font-size: 12px;  border-bottom: 1px solid #ddd; }
.faq .hgroup{ position: relative; *zoom: 1; }
.faq .hgroup .trigger{ position: absolute; bottom: 0; right: 0; font-size: 12px; border: 0;  padding: 0;  overflow: visible; background: none;  cursor: pointer; }
.faq ul{ list-style: none; margin: 0; padding: 0; }
.faq .q{ margin: 0; border-top: 1px solid #ddd; }
.faq .q td a.trigger{ 
display: block; padding: 10px 15px; text-align: left; font-weight: bold; color: #333; background: #fafafa; text-decoration: none !important; 
}
.faq .hide .q td a.trigger{  font-weight: normal; background: none; }
.faq .q a.trigger:hover,
.faq .q a.trigger:active,
.faq .q a.trigger:focus{ color: #000;  background: #f8f8f8; }
.faq .a{  margin: 0; padding: 10px 15px; line-height: 1.5; }
